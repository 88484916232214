import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Card, Grid, Typography, Box, Button, Stack } from "@mui/material";
import { createTeam } from "src/store/actions/team";
import Content from "src/layouts/Content";
import InputFields from "../../components/ui/formElements/InputFields";
import SelectFields from "src/components/ui/formElements/SelectFields";
import "../../styles/styles.scss";
import { timezones } from "src/data/timezones";
import { languages } from "src/data/langs";
import { getCities, getCountries, getStates } from "src/store/actions/countries";
import AddressView from "src/layouts/address/Address";
import { updateUser } from "src/store/actions/user";
import { updateAdmin } from "src/store/actions/auth";
import { setUserData } from "src/store/reducers/auth-slice";
import jsonwebtoken from "jsonwebtoken";
import cookie from "react-cookies";
import { validPhone } from "src/utils/Utils";
import InputPhoneField from "src/components/ui/formElements/InputPhoneField";

const MyProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, notification } = useSelector((state) => state.ui);
  const { userData } = useSelector((state) => state.auth);
  const { _id, name, email, mobile, phone } = userData;

  const [doNavigate, setDoNavigate] = useState(false)
  // Setting Default Values For Form
  const defaultValues = useForm({
    defaultValues: {
      name: name,
      email: email,
      mobile: mobile || "",
      phone: phone || "",
      //   fax: "",
      //   language: "",
      //   timezone: "",
      //   title: "",
      //   address: {
      //     street: "",
      //     city: "",
      //     state: "",
      //     country: "",
      //     postalCode: "",
      //   },
    },
  });

  // Initialization For React-Hook-Form

  const {
    control,
    handleSubmit,
    watch,
    clearErrors,
    setError,
    resetField,
    formState: { errors },
  } = defaultValues;

  const navigateToteams = () => {
    // navigate to /Teams
    navigate(-1);
  };

  useEffect(() => {
    if (notification?.status === "success" && doNavigate) {
      navigate(-1);
    }
    clearErrors();
    if (notification?.message === "User already exists") {
      setError(
        "email",
        { message: notification?.message },
        { shouldFocus: true }
      );
    }
  }, [notification]);


  // Function To Save The Team Data With API Call
  const save = (data) => {
    delete data.email;
    dispatch(updateAdmin({ _id, data })).then(res => {
      let userData = jsonwebtoken.decode(res.payload.jwt);
      dispatch(setUserData(userData));
      cookie.save("admin", JSON.stringify(res.payload), { path: -1 });
    });
    setDoNavigate(true)
  };

  return (
    <Content title="Update Profile">
      <form>
        <Card className="form-card">
          <Box className="form-heading">
            <Typography variant="h1">Info:</Typography>
          </Box>
          <Grid container columns={{ xs: 10 }} spacing={2}>
            <Grid item xs={2}>
              <InputFields
                fieldName="name"
                type="text"
                label="Name"
                control={control}
                rules={{
                  required: "Name is required",
                }}
                error={errors?.name}
              />
            </Grid>
            <Grid item xs={2}>
              <InputFields
                fieldName="email"
                type="email"
                label="Email"
                control={control}
                rules={{
                  required: "Email is required",
                  pattern: {
                    value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/gi,
                    message: "Incorrect Email",
                  },
                }}
                error={errors?.email}
                disabled
              />
            </Grid>
            {/* <Grid item xs={2}>
              <InputFields
                fieldName="password"
                type="password"
                label="Password"
                control={control}
                rules={{
                  required: "Password is required",
                }}
                error={errors?.password}
              />
            </Grid>
            <Grid item xs={2}>
              <InputFields
                fieldName="confirmPassword"
                type="password"
                label="Confirm Password"
                control={control}
                rules={{
                  required: "Confrim Password is required",
                  validate: (confirmPassword) => {
                    if (watch("password") != confirmPassword) {
                      return "Your passwords do no match";
                    }
                  },
                }}
                error={errors?.confirmPassword}
              />
            </Grid> */}
            <Grid item xs={2}>
              <InputPhoneField
                fieldName="mobile"
                type="tel"
                label="Mobile"
                control={control}
                rules={{
                  minLength: {
                    value: 11,
                    message: "Mobile should have at least 11 characters",
                  },
                  maxLength: {
                    value: 16,
                    message: "Mobile limit 16 characters",
                  },
                }}
                error={errors?.mobile}
                phone
              />
            </Grid>
            <Grid item xs={2}>

              <InputPhoneField
                fieldName="phone"
                type="tel"
                label="Phone"
                control={control}
                rules={{
                  minLength: {
                    value: 11,
                    message: "Phone should have at least 11 characters",
                  },
                  maxLength: {
                    value: 16,
                    message: "Phone limit 16 characters",
                  },
                }}
                error={errors?.phone}
                phone
              />
            </Grid>
            {/* <Grid item xs={2}>
              <InputFields
                fieldName="fax"
                type="text"
                min={0}
                label="Fax"
                control={control}
                rules={{
                  pattern: {
                    value: /^(\+?\d{1,}(\s?|\-?)\d*(\s?|\-?)\(?\d{2,}\)?(\s?|\-?)\d{3,}\s?\d{3,})$/gm,
                    message: "Invalid Fax Number",
                  },
                }}
                error={errors?.fax}
              />
            </Grid>
            <Grid item xs={2}>
              <SelectFields
                fieldName="language"
                label="Language"
                control={control}
                options={languages.map((lang) => {
                  return { label: lang.name, value: lang.name }
                })}
              />
            </Grid>
            <Grid item xs={2}>
              <SelectFields
                fieldName="timezone"
                label="Timezone"
                control={control}
                options={timezones.map(({ label, value }) => {
                  return { label, value }
                })}
              />
            </Grid>
            <Grid item xs={2}>
              <InputFields
                fieldName="title"
                type="text"
                min={0}
                label="Title"
                control={control}
              />
            </Grid> */}
          </Grid>
        </Card>
        {/* <AddressView control={control} errors={errors} watch={watch} resetField={resetField}/> */}

        <Stack direction="row" justifyContent="flex-end">
          <Button sx={{ mr: 2 }}
            variant="outlined"
            className="capitalize"
            onClick={navigateToteams}

          >
            Cancel
          </Button>
          <Button
            disabled={isLoading}
            type="submit"
            onClick={handleSubmit(save)}
            variant="contained"
          >
            Submit
          </Button>



        </Stack>

      </form>
    </Content>
  );
};

export default MyProfile;
