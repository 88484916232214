import cookie from "react-cookies";
import axios from "axios";
import { uiActions } from "src/store/reducers/ui-slice";
import { removeUser } from "src/store/reducers/auth-slice";

const getHeaders = (isUser) => {
  const token = cookie.load("admin") ? cookie.load("admin").jwt : "";
  if (isUser) {
    axios.defaults.headers.common["authorization"] = `Bearer ${token}`;
  } else {
    axios.defaults.headers.common["authorization-admin"] = `Bearer ${token}`;
  }

  const headers = {
    "Content-Type": "application/json",
  };
  return headers;
};

const dispatchNotification = (thunk, status, msg) => {
  if (status === "success") {
    thunk.dispatch(
      uiActions.showNotification({
        status: "success",
        title: "Operation Successful",
        message: msg,
      })
    );
  } else {
    thunk.dispatch(
      uiActions.showNotification({
        status: "error",
        title: "Operation Failed",
        message: msg,
      })
    );
  }
};

const call = (api, data, thunk, msg, isUser) => {
  let headers = getHeaders(isUser);
  if (thunk) {
    thunk.dispatch(uiActions.showLoading());
  }
  return axios[api.method](api.url, data, { headers })
    .then((response) => {
      if (thunk) {
        thunk.dispatch(uiActions.hideLoading());
        if (msg) {
          dispatchNotification(thunk, "success", msg);
        }
      }
      return response;
    })
    .catch((error) => {
      if (thunk) {
        thunk.dispatch(uiActions.hideLoading());
      }
      
      if (error.response.status == 401) {
        thunk.dispatch(removeUser())
      }

      if(typeof error.response?.data == 'string'){
        thunk.dispatch(
          uiActions.showNotification({
            status: "error",
            title: "Error!",
            message: error.response?.data,
          })
        );
        return Promise.reject([{ message: error.response?.data}]);
      }
      if(typeof error.response?.data?.errors[0]?.message == 'string'){
        if (error.response) {
          const data = error.response.data;
          const msg = typeof data === "string" ? data : data.errors[0].message;
          dispatchNotification(thunk, "error", msg);
          return Promise.reject(error.response.data.errors);
        } else if (error.request) {
          const msg = error.message;
          dispatchNotification(thunk, "error", msg);
          return Promise.reject([{ message: error.message }]);
        } else {
          const msg = error.message;
          dispatchNotification(thunk, "error", msg);
          return Promise.reject([{ message: error.message }]);
        }
      }
      else{
        thunk.dispatch(
          uiActions.showNotification({
            status: "error",
            title: "Error!",
            message: "Internal server error",
          })
        );
        return Promise.reject([{ message: "Internal server error" }]);
      }
    });
};
export default {
  call,
};
