import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { createUser, getUser, getUsersList, updateUser } from "../../store/actions/user";
import { getCompanies } from "src/store/actions/company";
import { Box, Button, Card, Grid, Stack, Typography } from "@mui/material";
import InputFields from "src/components/ui/formElements/InputFields";
import SelectFields from "src/components/ui/formElements/SelectFields";
import Content from "src/layouts/Content";
import { languages } from "src/data/langs";
import { timezones } from "src/data/timezones";
import { clearState } from "src/store/reducers/user-slice";
import {
  getCities,
  getCountries,
  getStates,
} from "src/store/actions/countries";
import { roles } from "src/data/roles";
import AddressView from "src/layouts/address/Address";
import InputPhoneField from "src/components/ui/formElements/InputPhoneField";

const UserFormPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { selectedCompany, id  } = useParams();
  useEffect(() => {
    dispatch(getCompanies());
  }, []);

  const { list } = useSelector((state) => state.company.fetching);
  const { isLoading, notification } = useSelector((state) => state.ui);

  const [doNavigate, setDoNavigate] = useState(false);
  useEffect(() => {
    dispatch(clearState());
  }, []);

  const defaultValues = useForm({
    defaultValues: {
      firstName: "",
      middleName: "",
      lastName: "",
      email: "",
      // password: "",
      // confirmPassword: "",
      mobile: "",
      phone: "",
      fax: "",
      lang: "",
      timezone: "",
      title: "",
      company: {
        companyId: selectedCompany ? selectedCompany : "",
        name: "",
        UID: "",
        assetReview: null,
      },
      manager: {
        name: "",
        userId: "",
      },
      role: "",
      address: {
        street: "",
        city: "",
        state: "",
        country: "",
        postalCode: "",
      },
    },
  });

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
    clearErrors,
    resetField,
    reset,
    register,
    watch,
  } = defaultValues;

  


  const companyId = watch("company.companyId");
  const managerId = watch("manager.userId");

  useEffect(() => {
    if(id)
      dispatch(getUser({ id, companyId })).then(res=>{
        reset(res.payload)
    })
  }, []);
  
  const { list: userList } = useSelector((state) => state.users.fetching);
  useEffect(() => {
    if (companyId) {
      resetField("manager.name", { defaultValue: "" });
      resetField("manager.userId");
      dispatch(getUsersList(companyId));
    }
  }, [companyId]);

  useEffect(() => {
    if (companyId) {
      const filteredData = list.filter((val) => {
        return val._id === companyId;
      });
      if (filteredData.length) {
        resetField("company.name", { defaultValue: filteredData[0].name });
        resetField("company.assetReview", {
          defaultValue: filteredData[0].assetReview,
        });
        resetField("company.UID", { defaultValue: filteredData[0].UID });
      }
    }
  }, [companyId]);

  useEffect(() => {
    if (managerId) {
      const filteredData = userList.filter((val) => {
        return val._id === managerId;
      });
      if (filteredData.length) {
        resetField("manager.name", { defaultValue: filteredData[0].firstName });
      }
    }
  }, [managerId]);

  useEffect(() => {
    if (notification?.status === "success" && doNavigate) {
      navigate(-1);
    }
    clearErrors();
    if (notification?.message === "User already exists") {
      setError(
        "email",
        { message: notification?.message },
        { shouldFocus: true }
      );
    }
  }, [notification]);

  // Function to save the data in the server
  const save = (data) => {
    delete data.confirmPassword;
    if (!data.manager.name) delete data.manager;

    if(id){
      dispatch(updateUser({ data, id }));
    }else{
      dispatch(createUser(data));
    }
    setDoNavigate(true);
  };

  return (
    <Content title={`${id?'Edit':'Create'} User`}>
      <form>
        <Card className="form-card">
          <Box className="form-heading">
            <Typography variant="h1">Selected Company: </Typography>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <SelectFields
                fieldName="company.companyId"
                label="Company"
                control={control}
                rules={{
                  required: "Company Name is required",
                }}
                disabled={selectedCompany && true}
                error={errors.company?.companyId}
                options={
                  selectedCompany
                    ? [
                        {
                          label: list.filter(
                            (val) => val._id === selectedCompany
                          )[0]?.name,
                          value: selectedCompany,
                        },
                      ]
                    : list.map((val) => {
                        return { label: val.name, value: val._id };
                      })
                }
                withSearch
              />
            </Grid>

            <input type="hidden" disabled {...register("company.name")} />
            <input
              type="hidden"
              disabled
              {...register("company.assetReview")}
            />
            <input type="hidden" disabled {...register("company.UID")} />
          </Grid>
          <Box className="form-heading">
            <Typography variant="h1">Info:</Typography>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <InputFields
                fieldName="firstName"
                type="text"
                label="First Name"
                control={control}
                rules={{
                  required: "First Name is required",
                }}
                error={errors?.firstName}
              />
            </Grid>
            <Grid item xs={3}>
              <InputFields
                fieldName="middleName"
                type="text"
                label="Middle Name"
                control={control}
              />
            </Grid>
            <Grid item xs={3}>
              <InputFields
                fieldName="lastName"
                type="text"
                label="Last Name"
                control={control}
                rules={{
                  required: "Last Name is required",
                }}
                error={errors?.lastName}
              />
            </Grid>
            <Grid item xs={3}>
              <InputFields
                fieldName="email"
                type="email"
                label="Email"
                control={control}
                rules={{
                  required: "Email is required",
                  pattern: {
                    value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/gi,
                    message: "Incorrect Email",
                  },
                }}
                error={errors?.email}
                disabled={id ? true : false}
              />
            </Grid>
            {/* <Grid item xs={3}>
              <InputFields
                fieldName="password"
                type="password"
                label="Password"
                control={control}
                rules={{
                  required: "Password is required",
                }}
                error={errors?.password}
              />
            </Grid>
            <Grid item xs={3}>
              <InputFields
                fieldName="confirmPassword"
                type="password"
                label="Confirm Password"
                control={control}
                rules={{
                  required: "Confrim Password is required",
                  validate: (confirmPassword) => {
                    if (watch("password") != confirmPassword) {
                      return "Your passwords do no match";
                    }
                  },
                }}
                error={errors?.confirmPassword}
              />
            </Grid> */}
            <Grid item xs={3}>
              <InputPhoneField
                    fieldName="mobile"
                    type="tel"
                    label="Mobile"
                    control={control}
                    rules={{
                      minLength: {
                        value: 11,
                        message: "Mobile should have at least 11 characters",
                      },
                      maxLength: {
                        value: 16,
                        message: "Mobile limit 16 characters",
                      },
                      required: "Mobile is required",
                    }}
                    error={errors?.mobile}
                    phone
                  />
            </Grid>
            <Grid item xs={3}>
              <InputPhoneField
                    fieldName="phone"
                    type="tel"
                    label="Phone"
                    control={control}
                    rules={{
                      minLength: {
                        value: 11,
                        message: "Phone should have at least 11 characters",
                      },
                      maxLength: {
                        value: 16,
                        message: "Phone limit 16 characters",
                      },
                      required: "Phone is required",
                    }}
                    error={errors?.phone}
                    phone
                  />
            </Grid>
            <Grid item xs={3}>
              <InputFields
                fieldName="fax"
                type="text"
                min={0}
                label="Fax"
                control={control}
                rules={{
                  pattern: {
                    value: /^[0-9]*$/gi,
                    message: "Fax Number Should Not Contain Alphabets",
                  },
                }}
                error={errors?.fax}
              />
            </Grid>
            <Grid item xs={3}>
              <SelectFields
                fieldName="role"
                label="Role"
                control={control}
                options={roles.map((lang) => {
                  return { label: lang.label, value: lang.value };
                })}
                rules={{
                  required: "Role is required",
                }}
                error={errors?.role}
                withSearch
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <SelectFields
                    fieldName="timezone"
                    label="Timezone"
                    control={control}
                    options={timezones.map(({label,value}) => {
                      return { label, value };
                    })}
                    withSearch
                  />
                </Grid>
                <Grid item xs={3}>
                  <SelectFields
                    fieldName="lang"
                    label="Language"
                    control={control}
                    options={
                      languages.map((lang) => {
                            return {
                              label: lang.name,
                              value: lang.name,
                            };
                        })
                    }
                    withSearch
                  />
                </Grid>
                <Grid item xs={3}>
                  <InputFields
                    fieldName="title"
                    type="text"
                    label="Title"
                    control={control}
                  />
                </Grid>

                {userList.length !== 0 && (
                  <>
                    <Grid item xs={3}>
                      <SelectFields
                        fieldName="manager.userId"
                        label="Manager"
                        control={control}
                        options={userList.map((val) => {
                          return { label: val.firstName, value: val._id };
                        })}
                        withSearch
                      />
                    </Grid>

                    <input type="hidden" {...register("manager.name")} />
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Card>

        <AddressView control={control} errors={errors} watch={watch} resetField={resetField}/>

        <Stack direction="row" justifyContent="flex-end" spacing={2}>
          <Button onClick={() => navigate(-1)} variant="outlined">
            Cancel
          </Button>
          <Button
            disabled={isLoading}
            onClick={handleSubmit(save)}
            type="submit"
            variant="contained"
          >
            Save
          </Button>
        </Stack>
      </form>
    </Content>
  );
};

export default UserFormPage;
