import React from "react";
import {
  Box,
  Button,
  Collapse,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import { getLabelFromCamelCase } from "src/utils/Utils";
import IOCheckbox from "src/components/ui/IOCheckbox";
import "../../styles/packages.scss";
import Input from "src/components/ui/formElements/Input";

const packagesForm = ({
  isLoading,
  data,
  onChangeHandler,
  allCheckHandler,
  actionButon,
  isAllChecked,
  isCreate = true,
}) => {
  const isPackageNameEmpty = data.name.trim() === "";
  const [expanded, setExpanded] = React.useState("");
  const handleExpandClick = (mainKey,key) => {
    setExpanded((prev) => (prev == `${mainKey}/${key}` ? "" : `${mainKey}/${key}`));
  };

  const checkFeild = (label, mainKey, key, childKey) => {
    let value = childKey
      ? data.permissions[mainKey][key][childKey]
      : data.permissions[mainKey][key];
    if (!childKey && !key) {
      value = data.permissions[mainKey];
    } else if (!childKey) {
      value = data.permissions[mainKey][key];
    } else {
      value = data.permissions[mainKey][key][childKey];
    }

    let InputItem =
      typeof value === "boolean" ? (
        <IOCheckbox
          onChange={(e) => {
            onChangeHandler(mainKey, key, childKey, e.target.checked);
          }}
          checked={value}
        />
      ) : (
        <Input
          type="number"
          value={value}
          onChange={(e) => {
            onChangeHandler(mainKey, key, childKey, parseInt(e.target.value));
          }}
          inputProps={{
            min: 3,
            max: 1000,
          }}
        />
      );

    if (key === "maxNumberOfUsers") {
      InputItem = (
        <Input
          type="number"
          value={value}
          onChange={(e) => {
            onChangeHandler(mainKey, key, childKey, parseInt(e.target.value));
          }}
          inputProps={{
            min: 1,
            max: 10,
          }}
        />
      );
    }

    return (
      <FormControlLabel
        // key={childKey}
        label={<Typography variant="h2">{label}</Typography>}
        labelPlacement="start"
        control={InputItem}
      />
    );
  };

  return (
    <Box className="company-permission-edit-page">
      <Box className="company-permission-edit">
        {isLoading ? (
          <Typography variant="h2">Loading...</Typography>
        ) : (
          <>
            <Box sx={{ width: 1 }}>
              <TextField
                value={data.name}
                onChange={(e) => {
                  onChangeHandler(null, null, null, e.target.value);
                }}
                sx={{ width: 500 }}
                label="Enter Package Name"
                error={!data.name}
                helperText={!data.name && "Package Name is required"}
              />
            </Box>

            {Object.keys(data.permissions).map((mainKey, i) => {
              const { isAllItemsChecked } = isAllChecked(
                data.permissions[mainKey]
              );
              return typeof data.permissions[mainKey] == "object" ? (
                <>
                  <Box className={`permission-maincontainer`}>
                    <Box className="permission-maincontainer-header">
                      <Typography variant={"h1"}>
                        {getLabelFromCamelCase(mainKey)}:
                      </Typography>
                      {mainKey !== 'userSettings' && <IOCheckbox
                        onChange={(e) =>
                          allCheckHandler(mainKey, null, e.target.checked)
                        }
                        checked={isAllItemsChecked}
                      />}
                    </Box>
                    {Object.keys(data.permissions[mainKey]).map((key) => {
                      let currentData = data.permissions[mainKey][key];
                      const label = getLabelFromCamelCase(key);
                      if (typeof currentData == "object") {
                        const { isAllItemsChecked, shouldShowCheckbox } =
                          isAllChecked(data.permissions[mainKey][key]);
                        return (
                          <Box
                            key={key}
                            className={`permission-container ${
                              Object.keys(currentData).length > 10
                                ? "two-width"
                                : ""
                            }`}
                          >
                            <Box
                              className="permission-container-header"
                              onClick={() => handleExpandClick(mainKey,key)}
                            >
                              <Typography variant={"h1"}>{label}</Typography>
                              <IOCheckbox
                                onChange={(e) =>
                                  allCheckHandler(
                                    mainKey,
                                    key,
                                    e.target.checked
                                  )
                                }
                                checked={isAllItemsChecked}
                              />
                            </Box>
                            <Collapse
                              in={expanded == `${mainKey}/${key}`}
                              timeout="auto"
                              unmountOnExit
                            >
                              {Object.keys(currentData).map((childKey) => {
                                return (
                                  <Box className="permission-container-body">
                                    {checkFeild(
                                      getLabelFromCamelCase(childKey),
                                      mainKey,
                                      key,
                                      childKey
                                    )}
                                  </Box>
                                );
                              })}
                            </Collapse>
                          </Box>
                        );
                      } else {
                        return (
                          <Box className="permission-maincontainer-body">
                            {checkFeild(label, mainKey, key)}
                          </Box>
                        );
                      }
                    })}
                  </Box>
                </>
              ) : (
                <Box className="permission-body">
                  {checkFeild(getLabelFromCamelCase(mainKey), mainKey)}
                </Box>
              );
            })}
          </>
        )}
      </Box>
      {!isLoading && (
        <Box className="permission-btn-row">
          {React.cloneElement(actionButon, { disabled: isPackageNameEmpty })}
        </Box>
      )}
    </Box>
  );
};

export default packagesForm;
