import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { RiPencilFill } from "react-icons/ri";
import {
  IconButton,
  Box,
  Typography,
  Button,
  Grid,
  Tooltip,
} from "@mui/material";
import { getCompanies } from "../../store/actions/company";
import { clearState } from "src/store/reducers/company-slice";
import { headCellsCompany } from "../../components/common/tablesData";
import Tables from "../../components/common/Tables";
import Content from "src/layouts/Content";
import { FaUsers } from "react-icons/fa";

const companyActions = ({ id, companyName }) => {
  const dispatch = useDispatch();

  return (
    <>
      <Box>
        <Tooltip title="View Company Users">
          <Link
            onClick={() => dispatch(clearState())}
            to={`/user/${id}`}
            state={{ companyName }}
          >
            <IconButton>
              <FaUsers size={18} />
            </IconButton>
          </Link>
        </Tooltip>
        <Tooltip title="Edit">
          <Link onClick={() => dispatch(clearState())} to={`/company/${id}`}>
            <IconButton>
              <RiPencilFill size={18} />
            </IconButton>
          </Link>
        </Tooltip>
      </Box>
    </>
  );
};

const CompanyList = () => {
  const dispatch = useDispatch();
  const { list } = useSelector((state) => state.company.fetching);
  const { isLoading } = useSelector((state) => state.ui);
  useEffect(() => {
    dispatch(getCompanies());
  }, []);
  return (
    <>
      {isLoading ? (
        <Typography variant="h2"> Fetching Companies..</Typography>
      ) : (
        <Content
          title="Companies"
          action={
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link to="/company/create">
                  <Button variant="contained">Create Company</Button>
                </Link>
              </Grid>
            </Grid>
          }
        >
          <Tables
            Action={companyActions}
            rows={list}
            headCells={headCellsCompany}
          />
        </Content>
      )}
    </>
  );
};

export default CompanyList;
