import { useState } from "react";
import { Box, Grid, Typography, Button, TextField } from "@mui/material";
import Input from "src/components/ui/formElements/Input";
import { Link } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
const LoginForm = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
  });
  console.log(props.isLoading)
  return (
    <form>
      <Box className="loginForm">
        <Typography variant="h6" className="sign-in-text">
          Sign In With
        </Typography>
        <Box className="ControlWrap">
          <Controller
            name="email"
            control={control}
            rules={{
              required: "Email is required",
              pattern: {
                value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/gi,
                message: "Incorrect Email",
              },
            }}
            render={({ field }) => {
              return (
                <>
                  <Typography
                    variant="body1"
                    component="label"
                    className="labelTag"
                  >
                    Username or Email
                  </Typography>
                  <TextField
                    required
                    className="loginField"
                    variant="outlined"
                    error={errors.email && true}
                    helperText={errors.email ? errors?.email.message : " "}
                    type="email"
                    inputRef={field.ref}
                    label="Username or Email"
                    value={field.value}
                    onChange={field.onChange}
                    fullWidth
                    autoComplete=""
                  />
                </>
              );
            }}
          />
        </Box>
        <Box className="ControlWrap">
          <Controller
            name="password"
            control={control}
            rules={{ required: "Password is required" }}
            render={({ field }) => {
              return (
                <>
                  <Typography
                    variant="body1"
                    component="label"
                    className="labelTag"
                  >
                    Password
                  </Typography>
                  <TextField
                    required
                    variant="outlined"
                    error={errors.password && true}
                    helperText={
                      errors.password ? errors?.password.message : " "
                    }
                    className="loginField"
                    type="password"
                    inputRef={field.ref}
                    label="Password"
                    value={field.value}
                    onChange={field.onChange}
                    fullWidth
                    autoComplete=""
                  />
                </>
              );
            }}
          />
        </Box>

        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          {/* <Grid item>
            <Togglebtn className="switchBtn" />
          </Grid> */}
          <Grid item>
            <Typography component="p" className="forgot-password">
              <Link to="/forgotpassword">Forgot Password?</Link>
            </Typography>
          </Grid>
        </Grid>
        <Box className="pt-4">
          <Button
            type="submit"
            variant="contained"
            fullWidth
            onClick={handleSubmit(props.loginHandler)}
            disabled={props.isLoading}
          >
            {props.isLoading? 'Signing..': 'SIGN IN'}
          </Button>
        </Box>
      </Box>
    </form>
  );
};

export default LoginForm;
