import { AiOutlineMinus } from "react-icons/ai";

export const getFormattedCell = (data) => {
  return data ? data : <AiOutlineMinus />;
};
export const getLabelFromCamelCase = (str) => {
  const words = str.split(/(?=[A-Z][a-z])/);

  const labels = {
    numberOfSlidePerPresentation: "Number Of Slides",
  };

  const label =
    labels[str] ||
    words.map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(" ");

  return label;
};
// export const validPhone = /[^0-9-+]/g;
export const validPhone = /[^0-9+()-]|([+-])\1+/g
export const onlyPhone = (str) => {
  if (str?.length > 0) {
    str = str?.replace(validPhone, '')
  }
  return str;
}