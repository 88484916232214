import { createSlice } from "@reduxjs/toolkit";
import { getCompanyData, getCompanies } from "../actions/company";

const initialState = {
  fetching: {
    list: [],
    company: {},
  },
};

const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    clearState: (state) => {
      state.fetching.company = {};
    },
  },
  extraReducers: {
    [getCompanies.fulfilled]: (state, action) => {
      state.fetching.list = action.payload;
    },
    [getCompanyData.fulfilled]: (state, action) => {
      state.fetching.company = action.payload;
    },
  },
});
export const { clearState } = companySlice.actions;
export default companySlice.reducer;
