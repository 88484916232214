import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../interfaces/api.interface";
import HttpService from "../../services/http.service";
import { uiActions } from "../reducers/ui-slice";

export const createCompany = createAsyncThunk(
  "createCompany",
  async (data, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.createCompany(),
        data,
        thunkAPI,
        "Your Record Has Been Saved"
      );
      if (response.data) {
        return response.data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getCompanies = createAsyncThunk(
  "getCompanies",
  async (data, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.getCompanies(),
        null,
        thunkAPI
      );
      if (response) {
        return response.data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getCompanyData = createAsyncThunk(
  "getCompanyData",
  async (id, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.getCompanyData(id),
        null,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateCompany = createAsyncThunk(
  "updateCompany",
  async ({ id, data }, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.updateCompany(id),
        data,
        thunkAPI,
        "Your Record Has Been Updated"
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
