import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  TableRow,
  TableCell,
  TableHead,
  TableSortLabel,
  Switch ,
} from "@mui/material";

//Icons for status field
import { AiOutlineClose } from "react-icons/ai";
//import { BsCheck2 } from "react-icons/bs";
import { getFormattedCell } from "src/utils/Utils";
import { updateUser } from "src/store/actions/user";
import { useDispatch } from "react-redux";

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const getComparator = (order, orderBy) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

const Tables = ({ rows, headCells, initialRows = 10, Action }) => {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(initialRows);
  const dispatch = useDispatch();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    if (isAsc) {
      setOrder("desc");
    } else if (order === "desc") {
      setOrderBy("");
      setOrder("asc");
    } else {
      setOrderBy(property);
      setOrder("asc");
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const [checked, setChecked] = React.useState(true);

  const handleChange = (event,row) => {
    console.log(row._id)
    setChecked(event.target.checked);
    let data = {
      status:row.status=='active'?'in-active':'active'
    }

    dispatch(updateUser({ data, id:row._id }));
  };
  return (
    <>
      <TableContainer>
        <Table aria-labelledby="tableTitle">
          {/* Table Header Starts*/}
          <TableHead>
            <TableRow>
              {headCells.map((headCell, index, array) => (
                <TableCell
                  sx={{
                    textOverflow: "ellipsis",
                    fontWeight: 900,
                    fontSize: "14px",
                  }}
                  key={headCell.id}
                  padding="normal"
                  align="left"
                  sortDirection={orderBy === headCell.id ? order : false}
                >
                  {headCell.sort ? (
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={createSortHandler(headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  ) : (
                    headCell.label
                  )}
                </TableCell>
              ))}
              {Action && (
                <TableCell
                  sx={{ textOverflow: "ellipsis" }}
                  align="right"
                  padding="normal"
                >
                  Action
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          {/* Table Header Endes*/}

          {/* Table Body Starts*/}
          <TableBody>
            {stableSort(rows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return (
                  <TableRow padding="normal" hover tabIndex={-1} key={row._id}>
                    {headCells.map(({ id }, index, array) => {

                      return (
                        <TableCell
                          sx={{ fontWeight: 400 }}
                          key={index}
                          align="left"
                        >
                          {id === "status" ? (
                            row[id] ? (
                              <Switch
                              // checked={checked}
                              checked={row["status"] == 'active'}
                              onChange={(res)=>{
                                // row[index]["status"] == 'pending'
                                console.log(res,row)
                                handleChange(res, row)
                              }}
                            />
                            ) : (
                              <AiOutlineClose size={22} />
                            )
                          ) : (
                            getFormattedCell(row[id]) // row.status
                          )}
                        </TableCell>
                      );
                    })}
                    {Action && (
                      <TableCell sx={{ paddingRight: 0 }} align="right">
                        <Action
                          id={row._id}
                          companyId={row.company?.companyId}
                          companyName={row.name}
                        />
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
            {emptyRows > 0 || rows.length === 0 ? (
              <TableRow>
              <TableCell colSpan={7} align="center">
                No Record Found
              </TableCell>
            </TableRow>
            ) : null}
          </TableBody>
          {/* Table Body Starts*/}
        </Table>
      </TableContainer>
      {/* Table Pagination*/}
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, { label: "All", value: rows.length }]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

export default Tables;
